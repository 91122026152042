
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Callback from './components/callback/callback';
import HomePage from './components/home-page/home-page';
import Header from './components/header/header';

import './App.css';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  // Hooks can't be used in the same component that creates the router,
  // so App is wrapped in an outer function
  let query = useQuery();
  return (
      <div className="App">
        <Header />

        <Switch>
          <Route path="/auth/callback">
            <Callback code={query.get("code")} />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </div>
  );
}

export default function RoutedApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
