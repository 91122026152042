import React from 'react';

import './home-page.css';

const HomePage = () => {

    return (
        <div>

        </div>
    );
};

export default HomePage;