import { useEffect, useState } from 'react';

import axios from 'axios';

import './user-dropdown.css';

const UserDropdown = ({onLogout}) => {
  const token = localStorage.getItem('oauth-demo-token');

  const [userInfo, setUserInfo] = useState(undefined);
  const [showDropdown, setShowDropdown] = useState(false);

  const loadUserInfo = async () => {
    const response = await axios.get(
      '/api/user/profile',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setUserInfo(response.data);
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const doLogout = () => {
    localStorage.removeItem('oauth-demo-token');
    setShowDropdown(false);
    setUserInfo(undefined);
    window.dispatchEvent(new Event('storage'));
    if(onLogout) {
      onLogout();
    }
  };

  const renderAvatar = () => (
    <div className='avatar-container' onClick={toggleDropdown}>
      <img className='avatar-img' src={userInfo.avatar} />
      <div className='dropdown-caret-container' onClick={toggleDropdown}>
        <span className='dropdown-caret'/>
      </div>
    </div>
  );

  return (
    <div>
      {userInfo ? renderAvatar() : undefined}
      <div className={!showDropdown ? 'user-dropdown-container' : 'user-dropdown-container user-dropdown-container-show'}>
        <ul className='dropdown-list'>
          <li><span>Logged in as <b>{userInfo?.username}</b></span></li>
          <li className='divider' />
          <li className='logout-link' onClick={doLogout}>Logout</li>
        </ul>
      </div>
    </div>
  );
};

export default UserDropdown;
