import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import './social-login-button.css';

const SocialLoginButton = () => {
    const client_id = 'd38ce7f5c5ec471cb7c2';
    const loginUrl = `https://github.com/login/oauth/authorize?client_id=${client_id}&scope=user:email`;

    return (
        <div className="gh-button-container">
            <button
                id="github-button"
                className="gh-button"
                onClick={() => {window.location.href=loginUrl}}
            >
                <FontAwesomeIcon
                    className="gh-icon"
                    icon={brands('github')}
                    size="2x"
                />
                <span className="gh-label">Sign In With GitHub</span>
            </button>
        </div>
    );
};

export default SocialLoginButton;