import { useEffect, useState } from 'react';
import {
  Link,
} from 'react-router-dom';

import SocialLoginButton from '../social-login-button/social-login-button';
import UserDropdown from '../user-dropdown/user-dropdown';

import './header.css';

const Header = ({}) => {
  const [token, setToken] = useState(localStorage.getItem('oauth-demo-token'));

  // Quick and dirty hack to avoid adding Redux right now
  const handleStorageChange = () => {
    setToken(localStorage.getItem('oauth-demo-token'));
  };
  window.addEventListener('storage', handleStorageChange);
  useEffect(() => {
    return () => { window.removeEventListener('storage', handleStorageChange); }
  });

  const renderLoginButtons = () => (
    <div className='social-login-container'>
      <SocialLoginButton />
    </div>
  );

  const renderUserDropdown = () => {
    return (
      <div className=''>
        <UserDropdown />
      </div>
    );
  };

  return (
    <div className='header-bar'>
      <nav className='nav'>
        <div className='link-container'>
          <Link className='link' to='/'>
            Home
          </Link>
        </div>
      </nav>
      { !token ? renderLoginButtons() : renderUserDropdown() }
    </div>
  );
};

export default Header;
