import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from '../spinner/spinner';

import './callback.css';

const Callback = ( { code } ) => {

    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);

    const getToken = async (code) => {
        setLoading(true);
        const response = await axios.post('/api/token', {code});
        setLoading(false);
        setToken(JSON.stringify(response.data));
        localStorage.setItem('oauth-demo-token', response.data.token);
        window.location.href='/';
    };

    useEffect(() => {
        getToken(code);
    }, [code]);

    return (
        <div>
            { loading ?
                (
                    <div className='loading-container'>
                        <div className='loading-text'>Logging in...</div>
                        <Spinner />
                    </div>
                )
            : <div /> }
        </div>
    );
}

export default Callback;